import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { evoDiscoveryUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { EvoDiscoveryState } from '../../integrations/types';

export const loadEvoDiscovery = (): Observable<EvoDiscoveryState> => {
    const obs = sendRetrieveRequest(evoDiscoveryUrl());
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<EvoDiscoveryState>) => {
            const discovery = convertToCamel<EvoDiscoveryState>(response);
            return observableOf(discovery);
        }),
    );
};
